export type CBox_ActionCreateAgentAgentPartData = {
  name: string;
  phone: string;
  zoneId: number;
  email?: string;
  identifier?: string;
};

export type CBox_ActionCreateAgentPermittedLockersPartData = {
  all: boolean;
  specific?: ({ id: string; } | { name: string })[];
};

export type CBox_ActionCreateAgentPermittedAction =
    "COURIER_RETRIEVE_EXPIRED"|"COURIER_TAKE_ORDERS"|"COURIER_PUT_ORDERS"|
    "CHANGE_PAPER"|"COURIER_ALL_ACTIONS";

export enum CBox_ActionCreateAgentPermittedActionEnum {
  COURIER_RETRIEVE_EXPIRED = "COURIER_RETRIEVE_EXPIRED",
  COURIER_TAKE_ORDERS = "COURIER_TAKE_ORDERS",
  COURIER_PUT_ORDERS = "COURIER_PUT_ORDERS",
  CHANGE_PAPER = "CHANGE_PAPER",
  COURIER_ALL_ACTIONS = "COURIER_ALL_ACTIONS"
}

export type CBox_ActionCreateAgentPermittedActionsPartData = {
  actionType: CBox_ActionCreateAgentPermittedAction;
};

export type CBox_PublicGetAgentDataParams = {
  identifier: string;
};

export type CBox_PublicGetAgentDataResponse = {
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  agent: CBox_ActionCreateAgentAgentPartData;
  permittedLockers: CBox_ActionCreateAgentPermittedLockersPartData;
  permittedActions: CBox_ActionCreateAgentPermittedActionsPartData[];
  access: {
    identificationCode: {
      hasCode: false;
    } | {
      hasCode: true;
      expiresAt: string;
    };
    password: {
      hasPassword: false;
      autoExpirationOffset: number;
    } | {
      hasPassword: true;
      expiresAt: string;
      autoExpirationOffset: number;
    }
  };
};

export type CBox_PublicGetAgentDataListParams = {
  identifier?: string;
  phone?: string;
  email?: string;
  name?: string;
  page?: number;
  pageSize?: number;
  includeDisabled?: boolean;
};

export type CBox_PublicGetAgentDataListResponse =
    CBox_PublicGetAgentDataResponse[];

export type CBox_ActionCreateAgentData = {
  query: {
    includeOpenCodeImage: boolean;
  };
  body: {
    agent: CBox_ActionCreateAgentAgentPartData;
    permittedLockers: CBox_ActionCreateAgentPermittedLockersPartData;
    permittedActions: CBox_ActionCreateAgentPermittedActionsPartData[];
    accessExpiration?: string | null;
    temporaryPasswordExpirationOffset?: number;
  };
};

export type CBox_ActionCreateAgentResponse = {
  identifier: string;
  lockerOpenCode: string;
  lockerOpenCodeImage: string;
};

export type CBox_ActionUpdateAgentData = {
  identifier: string;
  data?: Partial<Omit<CBox_ActionCreateAgentData["body"], "agent">> & {
    agent?: Partial<CBox_ActionCreateAgentAgentPartData>;
  }
  options?: {
    regenerateOpenCode?: boolean;
    generatePassword?: boolean;
    allowIdentificationChange?: boolean;
    includeOpenCodeImage?: boolean;
  }
};

export type CBox_ActionUpdateAgentResponse = {
  identifier: string;
  lockerOpen?: {
    code: string;
    expiration: string;
    codeImage?: string;
  }
  password?: {
    value: string;
    expiration: string;
  }
};

export type CBox_ActionDeleteAgentData = {
  identifier: string;
};

export type CBox_ActionDeleteAgentResponse = undefined;

export type CBox_GetAgentListIdentifierParams = undefined;

export type CBox_GetAgentListIdentifierResponse = {
  identifier: string;
  name: string;
  email: string;
  phone: string;
}[];

export type CBox_ActionSendAgentCodeEmailData = {
  identifier: string;
};

export type CBox_ActionSendAgentCodeEmailResponse = undefined;